@import '@styles/sass/mixins/rem';
@import '@styles/sass/variables';

.slider_with_cta {
  .swiper-container .swiper-wrapper .swiper-slide:last-child {
    margin-right: 0;
  }

  .swiper-pagination {
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: rem(-30);
    width: rem(40);
    height: rem(40);
    color: #005098;
  }

  .swiper-button-prev {
    left: rem(10) !important;
  }

  .swiper-button-next {
    right: rem(10) !important;
  }

  @media only screen and (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: rem(18);
    font-weight: bold;
    background-color: #fff;
    box-shadow: 0 rem(3) rem(6) #0000001a;
    border: rem(1) solid #f4f8fe;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    margin: 0 rem(4);
    width: rem(15);
    height: rem(13);
    display: block;
    background: url('https://rabbit-care-images.imgix.net/heart_44c1869696/heart_44c1869696.svg');
    background-repeat: no-repeat;
  }

  @media only screen and (max-width: 767px) {
    .mobile-bg {
      background-color: #ffffff;
    }
  }
}
