@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primaryColor: #005098;
    --secondaryColor: #fd9000;
    --accordionPanelBg: #f2f3fa;
    --buttonPrimaryBg: #005098;
    --buttonPrimaryBgHover: #003d74;
    --buttonDisabledBg: #ecebed;
    --buttonDisabledTextColor: #cbcbcb;
    --buttonDisabledBorder: #d9dbe8;
    --textColor: #4f4b66;
    --borderColor: #d8dbe9;
    --grayColor: #e9edf5;
    --placeholder: #a5aac0;
    --backgroundHoverColor: rgb(0, 80, 152, 0.1);
    --navTextColor: #005098;
    --navBarBorder: #e2e8f0;
    --footerLink: #97aac3;
    --footerLinkBuildingBg: #005098;
    --footerBg: #003d74;
    --footerText: #d9dbe8;
    --footerBorderLink: #f0f0f050;
    --white: #ffffff;
    --dropDownItemHover: #f2f3fa;
    --radioBgHover: #f2f3fa;
    --errorColor: #ff3333;
    --outlineColor: #e9edf5;
    --starFillColor: #FFDB4F;
    --defaultStarFillColor: #CED3E4;
    --suffixColor: #4f4b66;
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

body.bg-pattern {
  background-image: url('https://rabbit-care-images.imgix.net/bg_pattern_1fe6c9d667/bg_pattern_1fe6c9d667.svg') !important;
  background-repeat: repeat-y;
  background-position: top center;
  background-size: 100%;
}

label > button {
  pointer-events: none;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.close {
  cursor: pointer;
}

span.nowrap {
  white-space: nowrap;
  word-wrap: break-word;
}

span.inline-image img {
  display: inline;
  padding-right: 0.3rem;
}

/* Custom button from ckeditor */
.ck-button {
  text-align: center;
}

.ck-button a {
  color: #fff !important;
  padding: 13px 15px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none !important;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 268px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.table .ck-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.table .ck-button a {
  margin-top: 0;
  margin-bottom: 0;
  width: auto;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 12px;
}

.ck-button.button-blue a {
  background: #005098;
}

.ck-button.button-orange a {
  background: #f78d1d;
}

.ck-button.button-light-blue a {
  background: #58b8e0;
}

.ck-button.button-blue a:hover {
  background: #003d74;
}

.ck-button.button-orange a:hover {
  background: #f6840a;
}

.ck-button.button-light-blue a:hover {
  background: #0890c9;
}

@media only screen and (max-width: 768px) {
  .ck-button a {
    border-radius: 7px;
    width: 35vw;
    font-size: 3vw;
    padding: 7px;
  }

  .table .ck-button a {
    border-radius: 5px;
    font-size: 2vw;
    padding: 1.5vw 2vw;
  }
}

/* Bullet colors */
.heart-bullet ul li {
  list-style: none !important;
  background-image: url('/icons/heart-secondary.svg');
  background-repeat: no-repeat;
  background-position: 0 5px;
  padding-left: 22px;
  margin-bottom: 5px;
}

.heart-bullet.heart-bullet-v2 ul li {
  background-position: 0 1px;
  background-size: 14px 13px;
  line-height: 16px;
}

.heart-bullet.butterflypea ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_butterflypea_303968ab65/heart_butterflypea_303968ab65.svg');
}

.heart-bullet.dandelion ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_dandelion_1cbe9596de/heart_dandelion_1cbe9596de.svg');
}

.heart-bullet.emerald ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_emerald_aef50a6f45/heart_emerald_aef50a6f45.svg');
}

.heart-bullet.gold ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_gold_677773df57/heart_gold_677773df57.svg');
}

.heart-bullet.gray ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_gray_5aa084b866/heart_gray_5aa084b866.svg');
}

.heart-bullet.green ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_green_eec98e2984/heart_green_eec98e2984.svg');
}

.heart-bullet.lavender ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_lavender_d0c07a3f43/heart_lavender_d0c07a3f43.svg');
}

.heart-bullet.pink ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_pink_002b8f141d/heart_pink_002b8f141d.svg');
}

.heart-bullet.primary ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_primary_2d617fcb8d/heart_primary_2d617fcb8d.svg');
}

.heart-bullet.red ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_red_c9237ab8ff/heart_red_c9237ab8ff.svg');
}

.heart-bullet.secondary ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_secondary_74f1c0714b/heart_secondary_74f1c0714b.svg');
}

.heart-bullet.turquoise ul li {
  background-image: url('https://rabbit-care-images.imgix.net/heart_turquoise_19f5a0e574/heart_turquoise_19f5a0e574.svg');
}

.heart-bullet.checkmark ul li {
  background-image: url('https://rabbit-care-images.imgix.net/checkmark_5585acd124/checkmark_5585acd124.svg');
}

.rc-slider-tooltip {  
  z-index: 1;
}
.rc-slider-tooltip-content {  
  padding-inline: 8px;
  background: var(--primaryColor);    
  color: var(--white);
  border-radius: 0.4375rem;    
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 120px;
  z-index: 0;
  padding: 0.5rem;
  margin-top: 6px;
  margin-left: -10px;
  margin-right: -10px;
}

.rc-slider-tooltip-content .rc-slider-tooltip-inner {
  background-color: var(--primaryColor);
  color: var(--white);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-height: auto;
  height: 100%;
  margin: 0px;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  white-space: nowrap;
}

.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-arrow,
.rc-slider-tooltip-placement-bottomLeft .rc-slider-tooltip-arrow,
.rc-slider-tooltip-placement-bottomRight .rc-slider-tooltip-arrow {
  border-width: 0 14px 14px 14px;
  border-bottom-color: var(--primaryColor);
  top: -2px;
  margin-left: -14px;
}
.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-arrow {  
  left: 50%;  
}
.rc-slider-tooltip-placement-bottomLeft .rc-slider-tooltip-arrow {  
  left: 14px;
}
.rc-slider-tooltip-placement-bottomRight .rc-slider-tooltip-arrow {
  right: 0;
}

.tippy-popper .tippy-tooltip{
  background-color: var(--primaryColor);
  border-radius: 10px;
}

.tippy-popper .arrow-regular{
  border-bottom-color:  var(--primaryColor) !important;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}
.group:hover .group-hover\:rotate-180 {
  transform: rotate(180deg);
}

.notification {
  display: none;
}

#notification {
  display: none;
}

#notification:checked ~ .notification {
  display: block;
}
