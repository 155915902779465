@import '@styles/sass/mixins/rem';
@import '@styles/sass/variables';

.slideshow {
  .swiper {
    padding: 0 rem(20) rem(50);
    margin: 0 -20px;
    z-index: 0;
  }

  .swiper-container .swiper-wrapper .swiper-slide:last-child {
    margin-right: 0;
  }

  .swiper-pagination {
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    margin: 0 rem(4);
    width: rem(15);
    height: rem(13);
    display: block;
    background: url('https://rabbit-care-images.imgix.net/heart_44c1869696/heart_44c1869696.svg');
    background-repeat: no-repeat;
  }
}
