@import '@styles/sass/mixins/rem';
@import '@styles/sass/variables';

.customer_reviews {
  .swiper {
    padding: 0 rem(20) rem(50);
    margin: 0 -20px;
    z-index: 0;
  }

  .swiper .swiper-wrapper .swiper-slide:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 768px) {
    .swiper {
      padding: 0 rem(10) rem(40);
      margin: 0;
    }
  }

  .swiper-pagination {
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(14);
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: rem(-30);
    width: rem(40);
    height: rem(40);
    color: #005098;
  }

  .swiper-button-prev {
    left: rem(4);
  }

  .swiper-button-next {
    right: rem(4);
  }

  @media only screen and (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: rem(18);
    font-weight: bold;
    background-color: #fff;
    box-shadow: 0 rem(3) rem(6) #0000001a;
    border: rem(1) solid #f4f8fe;
    width: rem(40);
    height: rem(40);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heartIcon .swiper-pagination-bullet {
    margin: 0 rem(4);
    width: rem(15);
    display: block;
    background: url('https://rabbit-care-images.imgix.net/heart_44c1869696/heart_44c1869696.svg');
    background-repeat: no-repeat;
    height: rem(13);
  }
}
